<template>
  <div style="height: 100%">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" class="router-view">
      </router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      class="router-view"
    ></router-view>
  </div>
</template>
<script>
export default {
  created() {
    console.log(this.$route);
  },
};
</script>
